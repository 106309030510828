/** 本地开发环境
 * @enum alpha | seven | pre | beta
 */
const devEnv = 'alpha'

const APPID = 10021
const DAM_MAX_IMG_SIZE = 1 // DAM系统中jpg/png文件上传的最大限制 G 1
const DAM_MAX_VIDEO_SIZE = 10 // DAM系统中视频文件上传的最大限制 G
const DAM_MAX_FONT_SIZE = 50 // DAM系统中字体文件上传的最大限制 MB
const DAM_MAX_DOCUMENT_SIZE = 2 // DAM系统中文档类型文件上传的最大限制 G
const DAM_MAX_MUSIC_SIZE = 1 // DAM系统中音乐类型文件上传的最大限制 G

const CUSTOM_DAM_MAX_IMG_SIZE = 5 // DAM系统中jpg/png文件上传的最大限制 G
const CUSTOM_DAM_MAX_DOCUMENT_SIZE = 5 // DAM系统中文档类型文件上传的最大限制 G

const isDev = process.env.ENV === 'development' || !process.env.ENV
const env = isDev ? devEnv : process.env.ENV // 自定义环境变量

let url = {}
switch (env) {
  /**  生产环境 */
  case 'production':
    url = {
      PASSPORT_URL: 'https://passport.hellorf.com',
      BASE_URL: 'https://plus.hellorf.com/api/pro',
      BASE_URL_SERVER: 'https://api.hellorf.com/pro',
      EDITOR_BASE_URL: 'https://editor.hellorf.com/editor/image',
      HELLORF_BASE_URL: 'https://plus.hellorf.com/api/hellorf',
      AI_TOOL_URL: 'https://aitool-api.hellorf.com',
      SENTRY_DSN: 'https://1854c51987144234ae833ca3c8bbcfbc@sentry-online.in.zcool.cn/16',
      STATIC_IMG_URL: 'https://static.hellorf.com',
    }
    break
  /**  beta环境 */
  case 'beta':
    url = {
      PASSPORT_URL: 'https://passport.hellorf.com',
      BASE_URL: 'https://beta-plus.hellorf.com/api/pro',
      BASE_URL_SERVER: 'https://beta-api.hellorf.com/pro',
      EDITOR_BASE_URL: 'https://editor.hellorf.com/editor/image',
      HELLORF_BASE_URL: 'https://beta-plus.hellorf.com/api/hellorf',
      AI_TOOL_URL: 'https://beta-aitool-api.hellorf.com',
      SENTRY_DSN: 'https://1854c51987144234ae833ca3c8bbcfbc@sentry-online.in.zcool.cn/16',
      STATIC_IMG_URL: 'https://static-test.hellorf.com/beta',
    }
    break
  /**  pre环境
   * @enum pre | seven | alpha
   */
  case 'alpha':
    url = {
      BASE_URL: `https://pre-alpha-plus.hellorf.com/api/pro`,
      HELLORF_BASE_URL: 'https://pre-alpha-plus.hellorf.com/api/hellorf',
      EDITOR_BASE_URL: 'https://editor-alpha-hw.in.zcool.cn/editor/image',
      STATIC_IMG_URL: 'https://static-test.hellorf.com/alpha',
      PASSPORT_URL: 'https://pre-alpha-passport.hellorf.com',
    }
    break
  case 'seven':
    url = {
      BASE_URL: `https://pre-seven-api.hellorf.com/pro`,
      HELLORF_BASE_URL: 'https://pre-seven-api.hellorf.com/hellorf',
      EDITOR_BASE_URL: 'https://pre-seven-editor.hellorf.com/editor/image',
      STATIC_IMG_URL: 'https://static-test.hellorf.com/seven',
      PASSPORT_URL: 'https://pre-seven-passport.hellorf.com',
    }
    break
  case 'pre':
    url = {
      BASE_URL: 'https://pre-plus.hellorf.com/api/pro',
      BASE_URL_SERVER: 'https://pre-api.hellorf.com/pro',
      HELLORF_BASE_URL: 'https://pre-plus.hellorf.com/api/hellorf',
      // EDITOR_BASE_URL: 'https://pre-editor.hellorf.com/editor/image',
      EDITOR_BASE_URL: 'https://editor-alpha.in.zcool.cn/editor/image',
      // STATIC_IMG_URL: 'https://static-test.hellorf.com/pre',
      STATIC_IMG_URL: 'https://static-test.hellorf.com/alpha',
      PASSPORT_URL: 'https://pre-passport.hellorf.com',
    }
    break
  default:
    url = {
      BASE_URL: `https://pre-${devEnv}-api.hellorf.com/pro`,
      HELLORF_BASE_URL: `https://${devEnv}.hellorf.com/hellorf`,
      EDITOR_BASE_URL: `https://${devEnv}-editor.hellorf.com/editor/image`,
      STATIC_IMG_URL: `https://static-test.hellorf.com/${devEnv}`,
      PASSPORT_URL: `https://${devEnv}-passport.hellorf.com`,
    }
    break
}

const { PASSPORT_URL } = url
const PASSPORT_CHANGE_PHONE_URL = `${PASSPORT_URL}/account-safe/change-phone?appid=${APPID}`

module.exports = {
  APPID,
  CART_COUNT: 0,
  DAM_MAX_IMG_SIZE,
  DAM_MAX_VIDEO_SIZE,
  DAM_MAX_FONT_SIZE,
  DAM_MAX_DOCUMENT_SIZE,
  DAM_MAX_MUSIC_SIZE,
  API_TIMEOUT_INTERVAL: 30 * 1000,
  DOWNLOAD_TIMEOUT_INTERVAL: 60 * 1000,
  PASSPORT_URL,
  PASSPORT_LOGIN_URL: `${PASSPORT_URL}/?appid=${APPID}&status=1`,
  PASSPORT_MODIFY_USER_NAME_URL: `${PASSPORT_URL}/account-safe/change-name?appid=${APPID}`,
  PASSPORT_MODIFY_EMAIL_URL: `${PASSPORT_URL}/account-safe/change-email?appid=${APPID}`,
  PASSPORT_MODIFY_PHONE_URL: PASSPORT_CHANGE_PHONE_URL,
  PASSPORT_BIND_PHONE_URL: PASSPORT_CHANGE_PHONE_URL,
  PASSPORT_MODIFY_PASSWORD_URL: `${PASSPORT_URL}/account-safe/change-password?appid=${APPID}`,
  CUSTOM_DAM_MAX_IMG_SIZE,
  CUSTOM_DAM_MAX_DOCUMENT_SIZE,
  COOKIE_NAME: 'hellorf_session',
  COOKIE_DOMAIN: '.hellorf.com',
  AI_TOOL_URL: 'https://pre-plus.hellorf.com/api/aitool', // 测试环境默认地址
  SENTRY_DSN: 'https://1854c51987144234ae833ca3c8bbcfbc@sentry-online.in.zcool.cn/15',
  ...url,
}
